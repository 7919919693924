<script setup lang="ts">
    import type { AccordionContentData, BackgroundColorsPredefined } from '~/@types/cms';

    declare interface AccordionProps {
        data: AccordionContentData[];
        title?: string;
        description?: string;
        background?: BackgroundColorsPredefined;
    }

    withDefaults(defineProps<AccordionProps>(), {
        background: 'default',
    });
    const { colorMapper } = useStyle();
</script>

<template>
    <section :class="['org-accordion py-16 md:py-9', colorMapper[background], { 'text-white': background === 'black' }]">
        <mol-intro
            :title="title"
            :description="description" />
        <atm-grid>
            <div class="col-span-2 md:col-span-12">
                <atm-accordion-item
                    v-for="(item, index) in data"
                    :id="`item-${index}`"
                    :key="index"
                    :title="item.title"
                    :is-first="index === 0"
                    :background="background">
                    <atm-grid no-margin>
                        <cms-content-controller
                            :data="item.page.content"
                            class="col-span-2 md:col-span-12" />
                    </atm-grid>
                </atm-accordion-item>
            </div>
        </atm-grid>
    </section>
</template>

<style scoped>
    /* reset margins for nested accordion content */
    .org-accordion :deep(.text-block) {
        @apply ml-0;
    }
    .org-accordion :deep(.cms-feature-video-widget) {
        @apply mx-0 mt-0;
    }
    .org-accordion :deep(.org-highlights-container:not([class^='bg-woom-'])) {
        /* remove padding only if no background exsist */
        @apply py-0;
    }
    .org-accordion :deep(.mol-highlight) {
        @apply last:mb-0;
    }
    .org-accordion :deep(.mol-section-grid) {
        @apply mx-auto;
    }
    .org-accordion :deep(.mol-highlight) {
        @apply last:mb-0;
    }
    .org-accordion :deep(.text-block) {
        @apply py-0;
    }
    .org-accordion :deep(.cms-shop-info-placement) {
        @apply my-0;
    }
</style>
