<script setup lang="ts">
    import type { BackgroundColorsPredefined } from '~/@types/cms';

    declare interface AccordionItemProps {
        title: string;
        id: string;
        isFirst: boolean;
        background?: BackgroundColorsPredefined;
    }
    defineProps<AccordionItemProps>();

    const isOpen = ref(false);
</script>

<template>
    <div class="atm-accordion-item">
        <atm-heading
            level="h3"
            :font-bold="false">
            <button
                :id="`accordion-header-${id}`"
                :class="[
                    'flex min-h-[64px] w-full items-center justify-between py-3 text-left font-mono transition-colors',
                    {
                        'border-t border-woom-grey-mid': !isFirst,
                        'text-woom-red': isOpen && background !== 'black',
                        'text-woom-terra-coppa': isOpen && background === 'black',
                        'text-woom-black hover:text-woom-red': background !== 'black',
                        'text-woom-white hover:text-woom-terra-coppa': background === 'black',
                    },
                ]"
                :aria-expanded="isOpen"
                :aria-controls="`accordion-content-${id}`"
                @click.prevent="isOpen = !isOpen">
                <span>{{ title }}</span>
                <svg
                    class="ml-8 shrink-0 fill-current"
                    width="16"
                    height="16">
                    <rect
                        y="7"
                        width="16"
                        height="2"
                        rx="1"
                        :class="['origin-center transform transition duration-200 ease-out', { '!rotate-180': isOpen }]" />
                    <rect
                        y="7"
                        width="16"
                        height="2"
                        rx="1"
                        :class="['origin-center rotate-90 transform transition duration-200 ease-out', { '!rotate-180': isOpen }]" />
                </svg>
            </button>
        </atm-heading>
        <div
            :id="`accordion-content-${id}`"
            :aria-labelledby="`accordion-header-${id}`"
            :aria-hidden="!isOpen"
            role="region"
            class="accordion-content grid">
            <div class="accordion-slot-content">
                <slot></slot>
            </div>
        </div>
    </div>
</template>
<style scoped>
    .accordion-content {
        @apply grid-rows-[0fr] opacity-0 transition-all duration-300 will-change-transform;
    }
    .accordion-content[aria-hidden='false'] {
        @apply grid-rows-[1fr] opacity-100;
        transition:
            grid-template-rows 0.5s,
            opacity 0.7s;
    }
    .accordion-content[aria-hidden='true'] .accordion-slot-content {
        @apply overflow-hidden;
    }
    .accordion-slot-content :deep(> *) {
        /* pt-2 for background colours and video to not create too much white space between button and content start */
        @apply pb-6 pt-2 md:pb-8;
    }
</style>
